import { Button, Box, Grid } from "@mui/material";
import Speech from "react-text-to-speech";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box className="center">
      <Grid container spacing={2} width="100vw" textAlign="center" mb="50px">
        <Grid item xs={4}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/lettre1");
            }}
          >
            Lettres 1
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/lettre2");
            }}
          >
            Lettres 2
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/lettre3");
            }}
          >
            Lettres 3
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={6}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/chiffre1");
            }}
          >
            Chiffres 1
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/chiffre2");
            }}
          >
            Chiffres 2
          </Button>
        </Grid>
        {/* <Grid item xs={6}>
          <Button
            className="btn"
            onClick={() => {
              navigate("/chiffre3");
            }}
          >
            Chiffres 3
          </Button>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default HomePage;
