import { Button, Box, Grid } from "@mui/material";
import { useState } from "react";
import TextToSpeech from "../../components/TextToSpeech";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import correct from "./correct.mp3";
import wrong from "./wrong.mp3";

const LetterPage2 = () => {
  const navigate = useNavigate();
  const [playCorrect] = useSound(correct);
  const [playWrong] = useSound(wrong);

  const randomLetter = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;

    const c = characters.charAt(Math.floor(Math.random() * charactersLength));

    return c;
  };

  const [letter, setLetter] = useState(randomLetter());

  const randomize = (length) => {
    let result = [];
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    result.push(letter);
    let counter = 1;
    while (result.length < length) {
      const c = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(c) && c != letter) {
        result.push(c);
      }

      counter += 1;
    }

    const shuffledRes = result.sort((a, b) => 0.5 - Math.random());

    return shuffledRes;
  };

  const [letters, setLetters] = useState(randomize(4));

  const checkAnswer = (val) => {
    if (val === letter) {
      playCorrect();
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } else {
      playWrong();
    }
  };

  return (
    <Box className="center">
      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={12}>
          <TextToSpeech text={letter} image="speaker" />
        </Grid>
      </Grid>

      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={12}>
          <button
            className="anwser"
            onClick={() => {
              checkAnswer(letters[0]);
            }}
          >
            <img
              src={`./assets/images/${letters[0]}.png`}
              alt={`start${letters[0]}`}
              style={{ width: "100px", height: "100px" }}
            />
          </button>
        </Grid>
      </Grid>
      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={6}>
          <button
            className="anwser"
            onClick={() => {
              checkAnswer(letters[1]);
            }}
          >
            <img
              src={`./assets/images/${letters[1]}.png`}
              alt={`start${letters[1]}`}
              style={{ width: "100px", height: "100px" }}
            />
          </button>
        </Grid>
        <Grid item xs={6}>
          <button
            className="anwser"
            onClick={() => {
              checkAnswer(letters[2]);
            }}
          >
            <img
              src={`./assets/images/${letters[2]}.png`}
              alt={`start${letters[2]}`}
              style={{ width: "100px", height: "100px" }}
            />
          </button>
        </Grid>
      </Grid>

      <Grid container spacing={2} width="100vw" textAlign="center" mb="40px">
        <Grid item xs={12}>
          <button
            className="anwser"
            onClick={() => {
              checkAnswer(letters[3]);
            }}
          >
            <img
              src={`./assets/images/${letters[3]}.png`}
              alt={`start${letters[3]}`}
              style={{ width: "100px", height: "100px" }}
            />
          </button>
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
          <Grid item xs={12}>
            <Button
              onClick={() => {
                navigate("/");
              }}
              className="btn"
            >
              Retour
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LetterPage2;
