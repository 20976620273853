import { Button, Box, Grid } from "@mui/material";
import { useState } from "react";
import TextToSpeech from "../../components/TextToSpeech";
import { useNavigate } from "react-router-dom";
import Dictaphone from "../../components/Dictaphone";
import CustomSpeech from "../../components/CustomSpeech";

const LetterPage3 = () => {
  const navigate = useNavigate();

  const randomLetter = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;

    const c = characters.charAt(Math.floor(Math.random() * charactersLength));

    return c;
  };

  const letter = randomLetter();

  var suite = "";
  switch (letter) {
    case "a":
      suite = " Comme Anatole";
      break;
    case "b":
      suite = " Comme Banane";
      break;
    case "c":
      suite = " Comme Cerise";
      break;
    case "d":
      suite = " Comme David";
      break;
    case "e":
      suite = " Comme école";
      break;
    case "f":
      suite = " Comme Françoise";
      break;
    case "g":
      suite = " Comme Gaspard";
      break;
    case "h":
      suite = " Comme Hercule";
      break;
    case "i":
      suite = " Comme Isidor";
      break;
    case "j":
      suite = " Comme Jules";
      break;
    case "k":
      suite = " Comme Koala";
      break;
    case "l":
      suite = " Comme Lor";
      break;
    case "m":
      suite = " Comme Maman";
      break;
    case "n":
      suite = " Comme Neyte";
      break;
    case "o":
      suite = " Comme Olivia";
      break;
    case "p":
      suite = " Comme Patrick";
      break;
    case "q":
      suite = " Comme Quentin";
      break;
    case "r":
      suite = " Comme Romane";
      break;
    case "s":
      suite = " Comme Sophie";
      break;
    case "t":
      suite = " Comme Thierry";
      break;
    case "u":
      suite = " Comme Uzumaki";
      break;
    case "v":
      suite = " Comme Vincent";
      break;
    case "w":
      suite = " Comme Walter";
      break;
    case "x":
      suite = " Comme Xylophone";
      break;
    case "y":
      suite = " grec Comme Yoyo";
      break;
    case "z":
      suite = " Comme Zèbre";
      break;
    default:
      break;
  }

  const clickNext = (val) => {
    document.getElementById("play-button").click();
    setTimeout(() => {
        window.location.reload(true);
      }, 3000);
  };

  return (
    <Box className="center">
      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={12}>
          {/* LISTEN VOICE */}
          <Dictaphone letter={letter} />
        </Grid>
      </Grid>

      <Grid container spacing={2} width="100vw" textAlign="center" mb="40px">
        <Grid item xs={12}>
          <img
            src={`./assets/images/${letter}.png`}
            alt={`pause${letter}`}
            style={{ width: "100px", height: "100px" }}
          />
        </Grid>
      </Grid>

      <CustomSpeech text={letter}/>
      <Box>
        <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
          <Grid item xs={6}>
            <Button
              onClick={() => {
                navigate("/");
              }}
              className="btn"
            >
              Retour
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                clickNext();
              }}
              className="btn"
            >
              Suivant
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LetterPage3;
