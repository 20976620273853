import { Button, Box, Grid } from "@mui/material";
import { useState } from "react";
import TextToSpeech from "../../components/TextToSpeech";
import { useNavigate } from "react-router-dom";

const NumberPage1 = () => {
  const navigate = useNavigate();
  const randomNumbers = (length) => {
    let result = [];
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (result.length < length) {
      const c = characters.charAt(Math.floor(Math.random() * charactersLength));
      if (!result.includes(c)) {
        result.push(c);
      }
      counter += 1;
    }
    return result;
  };

  const [numbers, setNumbers] = useState(randomNumbers(4));

  return (
    <Box className="center">
      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={12}>
          <TextToSpeech text={numbers[0]} />
        </Grid>
      </Grid>
      <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
        <Grid item xs={6}>
          <TextToSpeech text={numbers[1]} />
        </Grid>
        <Grid item xs={6}>
          <TextToSpeech text={numbers[2]} />
        </Grid>
      </Grid>

      <Grid container spacing={2} width="100vw" textAlign="center" mb="40px">
        <Grid item xs={12}>
          <TextToSpeech text={numbers[3]} />
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={2} width="100vw" textAlign="center" mb="20px">
          <Grid item xs={6}>
            <Button
              onClick={() => {
                navigate("/");
              }}
              className="btn"
            >
              Retour
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setNumbers(randomNumbers(4));
              }}
              className="btn"
            >
              Suivant
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NumberPage1;
