import Speech from "react-text-to-speech";
import { Box } from "@mui/material";

const TextToSpeech = ({ text, image }) => {
  if(!image){
    image = text;
  }
    var suite = "";
    switch(text){
        case "a": 
        suite = " Comme Anatole"
        break;
        case "b": 
        suite = " Comme Banane"
        break;
        case "c": 
        suite = " Comme Cerise"
        break;
        case "d": 
        suite = " Comme David"
        break;
        case "e": 
        suite = " Comme école"
        break;
        case "f": 
        suite = " Comme Françoise"
        break;
        case "g": 
        suite = " Comme Gaspard"
        break;
        case "h": 
        suite = " Comme Hercule"
        break;
        case "i": 
        suite = " Comme Isidor"
        break;
        case "j": 
        suite = " Comme Jules"
        break;
        case "k": 
        suite = " Comme Koala"
        break;
        case "l": 
        suite = " Comme Lor"
        break;
        case "m": 
        suite = " Comme Maman"
        break;
        case "n": 
        suite = " Comme Neyte"
        break;
        case "o": 
        suite = " Comme Olivia"
        break;
        case "p": 
        suite = " Comme Patrick"
        break;
        case "q": 
        suite = " Comme Quentin"
        break;
        case "r": 
        suite = " Comme Romane"
        break;
        case "s": 
        suite = " Comme Sophie"
        break;
        case "t": 
        suite = " Comme Thierry"
        break;
        case "u": 
        suite = " Comme Uzumaki"
        break;
        case "v": 
        suite = " Comme Vincent"
        break;
        case "w": 
        suite = " Comme Walter"
        break;
        case "x": 
        suite = " Comme Xylophone"
        break;
        case "y": 
        suite = " grec Comme Yoyo"
        break;
        case "z": 
        suite = " Comme Zèbre"
        break;
        default: break;
    }
    
  return (
    <Speech
      text={`${text} ${suite}`}
      rate={0.8}
      pitch={1}
      volume={1}
      lang="fr"
      onError={() => console.error("Browser not supported!")}
    >
      {({ speechStatus, start, pause, stop }) => (
        <Box>
          {speechStatus !== "started" && (
            <button className="my-start-btn" onClick={start}>
              <img
                src={`./assets/images/${image}.png`}
                alt={`start${text}`}
                style={{ width: "100px", height:"100px" }}
              />
            </button>
          )}
          {speechStatus === "started" && (
            <button className="my-pause-btn" onClick={pause} disabled>
                <img
                src={`./assets/images/${image}.png`}
                alt={`pause${text}`}
                style={{ width: "100px", height:"100px" }}
              />
            </button>
          )}
          <button className="my-stop-btn" onClick={stop}>
            Stop Speech
          </button>
        </Box>
      )}
    </Speech>
  );
};

export default TextToSpeech;
