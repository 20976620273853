import React, { useState } from 'react'
import DictaphoneWidgetA from '../../components/DictaphoneWidgetA'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphones = () => {
  const listenContinuously = () => SpeechRecognition.startListening({
    continuous: true,
    language: 'en-GB'
  })
  const listenContinuouslyInChinese = () => SpeechRecognition.startListening({
    continuous: true,
    language: 'zh-CN'
  })
  const listenOnce = () => SpeechRecognition.startListening({ continuous: false })

  return (
    <div>
      <DictaphoneWidgetA />
      <button onClick={listenOnce}>Listen once</button>
      <button onClick={listenContinuously}>Listen continuously</button>
      <button onClick={listenContinuouslyInChinese}>Listen continuously (Chinese)</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={SpeechRecognition.removePolyfill}>Remove polyfill</button>
    </div>
  )
}

export default Dictaphones;