import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import HomePage from "./scenes/homePage";
import LetterPage1 from "./scenes/letterPage1";
import LetterPage2 from "./scenes/letterPage2";
import LetterPage3 from "./scenes/letterPage3";
import NumberPage1 from "./scenes/numberPage1";
import NumberPage2 from "./scenes/numberPage2";
import NumberPage3 from "./scenes/numberPage3";
import Dictaphones from "./scenes/dictaphonesPage";
import { useState, createContext } from "react";

const lettersPage2 = [];

export const ContextData = createContext();


function App() {

  return (
    <div className="App">
     
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route path="/" element={ <HomePage />} />
          <Route path="/lettre1" element={<LetterPage1 />} />
          <Route path="/lettre2" element={ <ContextData.Provider value={lettersPage2}><LetterPage2 /></ContextData.Provider>} />
          <Route path="/lettre3" element={<LetterPage3 />} />
          <Route path="/chiffre1" element={<NumberPage1 />} />
          <Route path="/chiffre2" element={<NumberPage2 />} />
          <Route path="/chiffre3" element={<NumberPage3 />} />
          <Route path="/dictaphone" element={<Dictaphones />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
