/* import React from 'react';
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const appId = '<INSERT_SPEECHLY_APP_ID_HERE>';
const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);

const Dictaphone = () => {
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const startListening = () => SpeechRecognition.startListening({ continuous: true });

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button
        onTouchStart={startListening}
        onMouseDown={startListening}
        onTouchEnd={SpeechRecognition.stopListening}
        onMouseUp={SpeechRecognition.stopListening}
      >Hold to talk</button>
      <p>{transcript}</p>
    </div>
  );
};
export default Dictaphone; */

import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useSound from "use-sound";
import correct from "./correct.mp3";
import wrong from "./wrong.mp3";

const Dictaphone = ({ letter }) => {
  const [playCorrect] = useSound(correct);
  const [playWrong] = useSound(wrong);

  var suite = "";
  switch (letter) {
    case "a":
      suite = " Comme Anatole";
      break;
    case "b":
      suite = " Comme Banane";
      break;
    case "c":
      suite = " Comme Cerise";
      break;
    case "d":
      suite = " Comme David";
      break;
    case "e":
      suite = " Comme école";
      break;
    case "f":
      suite = " Comme Françoise";
      break;
    case "g":
      suite = " Comme Gaspard";
      break;
    case "h":
      suite = " Comme Hercule";
      break;
    case "i":
      suite = " Comme Isidor";
      break;
    case "j":
      suite = " Comme Jules";
      break;
    case "k":
      suite = " Comme Koala";
      break;
    case "l":
      suite = " Comme Lor";
      break;
    case "m":
      suite = " Comme Maman";
      break;
    case "n":
      suite = " Comme Neyte";
      break;
    case "o":
      suite = " Comme Olivia";
      break;
    case "p":
      suite = " Comme Patrick";
      break;
    case "q":
      suite = " Comme Quentin";
      break;
    case "r":
      suite = " Comme Romane";
      break;
    case "s":
      suite = " Comme Sophie";
      break;
    case "t":
      suite = " Comme Thierry";
      break;
    case "u":
      suite = " Comme Uzumaki";
      break;
    case "v":
      suite = " Comme Vincent";
      break;
    case "w":
      suite = " Comme Walter";
      break;
    case "x":
      suite = " Comme Xylophone";
      break;
    case "y":
      suite = " grec Comme Yoyo";
      break;
    case "z":
      suite = " Comme Zèbre";
      break;
    default:
      break;
  }

  const commands = [
    {
      command: letter + suite,
      callback: (command, spokenPhrase, similarityRatio) => {
        console.log(
          `${command} and ${spokenPhrase} are ${similarityRatio * 100}% similar`
        );
        if (similarityRatio * 100 > 55) {
          playCorrect();
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        } else {
          playWrong();
        }
      },
      // If the spokenPhrase is "Benji", the message would be "Beijing and Benji are 40% similar"
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2,
    },
  ];

  const {
    /* transcript,*/
    listening, 
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });
  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true, language: "fr-FR" });

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
       <p> {listening ? 'Parlez s\'il vous plaît' : ''}</p>
      <button
        onTouchStart={startListening}
        onMouseDown={startListening}
        onTouchEnd={SpeechRecognition.stopListening}
        onMouseUp={SpeechRecognition.stopListening}
      >
        {" "}
        <img
          src={`./assets/images/micro.png`}
          alt={`micro`}
          style={{ width: "100px", height: "100px" }}
          onContextMenu={(e)=> e.preventDefault()}
        />
      </button>
      {/*  <p>{transcript}</p> */}
    </div>
  );
};
export default Dictaphone;
